/* @tailwind base; */


:root{
    --primary-color: #5983FC;
    --secondary-color: #3E60C1;
    --third-color: #EFEFEF;
    --black: #161617;
    --lightBlack: #313131;
    --lightGrey: #F2F2F2;
    --grey: #8e8e8f;
    --blue: #0071e3;
    --white: #fafafc;
    --highlight-yellow: #c48003;
  }


  @font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
  }
/* The css Code of particular component/page is started with component name - type */
body{
  overflow: hidden;
}
.landingpageiframe{
  margin: -0.09rem 0rem 0rem -0.09rem;
}
/* 1. Header-component */
.HeaderMain{
  
}
/* 2. LandingPage-page */
.landing-page-banner{
    display:flex;
    justify-content:center;
}
.landing-page-title{
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0rem 0rem 0rem;
}
.landing-page-title-h2{
    font-family: "San Francisco", Arial, sans-serif;
    color: var(--lightBlack);
    font-size: 3rem;
    font-weight: 650;
    font: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
@media only screen and (max-width: 900px) {
  .landing-page-title{
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin: 2rem 0rem 0rem 0rem; */
}
.landing-page-title-h2{
  font-family: "San Francisco", Arial, sans-serif;
  color: var(--lightBlack);
  font-size: 3rem;
  font-weight: 650;
  /* padding: 0rem 2rem 0rem 2rem; */
  text-align: center;
}
.landing-page-steps1{
  display: flex;
  flex-direction: column;
  
}

}
.landing-page-steps1-title{
  font-family: "San Francisco", Arial, sans-serif;
  color: var(--lightBlack);
  font-size: 2rem;
  font-weight: 650;
  text-align: center;
}
.landing-page-steps1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin: 2rem 2rem 2rem 2rem;
}
.landing-page-steps1-title-p{
  color: var(--black);
  font-size: 2.5rem;
}
.landing-page-steps1-image{
  height: 26rem;
  width: 15rem;
  border-radius: 1.5rem;
}
/* 3. UserListComponent-component */
/* Styles for screens less than 900px wide */
@media (max-width: 899px) {
  .UserListComponent {
    background-color: var(--third-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 5rem;
    border-radius: 1rem;
    margin: 1rem 1rem 0rem 1rem;
  }
}

/* Styles for screens 900px wide or wider */
@media (min-width: 900px) {
  .UserListComponent {
    background-color: var(--third-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 5rem;
    border-radius: 1rem;
    margin: 1rem 1rem 0rem 1rem;
  }
}


.UserListComponentImg{
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  margin: 1rem 1rem 1rem 1rem;
}
.UserListComponentContentp{
  margin: -0.6rem 0rem 1rem 0rem;
}
.UserListComponentContent{
 font-size: 0.75rem; 
}
.UserListComponentButton{
  margin: 0rem 0rem 0rem 1rem;
}

/* 4. TabsMain-component */
.TabsList{
  margin: 0rem 0rem 0rem 0rem;
}
.TabsMain{
  display: flex;
  justify-content: center;
  align-items: center;
}
.asd{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

/* 4. CreateFormComponent-component */

.CreateFormButtonComponent{
  height: 20rem;
  width: 30rem;
  
  margin: 1.8rem 0rem 0rem 0rem;
}
.CreateFormButtonComponent-main{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8rem 0rem 0rem 0rem;
}
.PageHeading{
  text-align: center;
  
}
.formgeneral{
  margin: 1rem 1rem 1rem 1rem;
}

/* SharePage - Page */

.sharepage{
  
  margin: 1rem 1rem 1rem 1rem;
}

.sharepage-top{
  
  margin: 1rem 1rem 1rem 1rem;
}

.sharepage-top-userimage{
  height: 6rem;
  width: 6.2rem;
  border-radius: 5rem;
  /* border: 2px solid var(--highlight-yellow); */
}

.sharepage-top-userimagediv{
  display: flex;
  justify-content: center;
  align-items: center;
  
}




.sharepage-top-famousSocialMedia{
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem 1rem 1rem;
}
.sharepage-top-username-h{
  font-family: "San Francisco", Arial, sans-serif;
  color: var(--lightBlack);
  font-size: 1.2rem;
  text-align: center;
  margin: 0rem 0rem 0rem 0rem;
  
}
.sharepage-link-linklist{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 1rem 1rem 1rem;
  
}

.sharepage-top-username-p{
  font-family: "San Francisco", Arial, sans-serif;
  color: var(--lightBlack);
  text-align: center;
  margin: 0rem 0rem 0rem 0rem;
}

.sharepage-top-userDetails{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  
}

.sharepage-top-username{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  
}
/* 5. SharePage-component */
.sharepage-contentlist{
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
  overflow: auto;
  height: 20rem;
  margin: 0rem 0rem 0rem 0rem;
  /*  */
}
.sharepage-contentlist-items{
  margin: 0rem 0rem 0rem 0rem;
  /* overflow: auto; */
}


@media (max-width: 440px) {

  .SharePageListComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    margin: 0rem 0rem 0.6rem 0rem;
    background-color: var(--third-color);
    border-radius: 3rem;
    padding: 0.2rem 2.5rem 0.2rem 2.5rem;
    width: 15.4rem;
    /*  */
  }
  #searchField{
    width: 5.5rem;
    margin: 0rem 0.2rem 0rem 0rem;
  }
  .SharePageListComponentinfo-h{
    font-size: 0.85rem;
    margin: 0.5rem 0rem -0.7rem 0rem;
  }

  .SharePageListComponentbutton{
    margin: 0rem 0rem 0rem 0rem !important;
    height: 1.5rem !important;
    width: 3rem !important;
    border-radius: 4rem !important;
    /* border: none; */
    background-color: var(--secondary-color) !important;
    font-size: 0.7rem !important;
  }

  .SharePageListComponentimagefile{
    height: 2rem;
    width: 2rem;
    border-radius: 5rem;
  }
  .SharePageListComponentimage{
    margin: 0rem 0rem 0rem -0.2rem !important;
  }
  .famoussocialmediaicon{
    border-radius: 10rem;
    height: 2.2rem;
    width: 2.2rem;
    
  }

  /* buy me cake animation */

.box {
  position: relative;
  display: block;
  height: 2.2rem;
  width: 2.2rem;
  background: linear-gradient(0deg, rgb(255, 255, 255), #fdfdfd);
}

.glowing::before{
  border-radius: 5rem;
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, #e8f74d, #ff6600d9, #00ff66, #13ff13, #ad27ad, #bd2681, #6512b9, #ff3300de, #5aabde);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  
  50% {
    background-position: 400% 0;
  }
  
  100% {
    background-position: 0 0;
  }
}


}


@media (max-width: 600px) and (min-width: 440px) {

  .linklisttopdetailstitle{
    font-size: 0.8rem;
  }
  .SharePageListComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    margin: 0rem 0rem 0.6rem 0rem;
    background-color: var(--third-color);
    border-radius: 3rem;
    padding: 0.2rem 2.5rem 0.2rem 2.5rem;
    width: 19rem;
    /*  */
  }
  #searchField{
    width: 8rem;
  }
  .SharePageListComponentinfo-h{
    font-size: 0.85rem;
    margin: 0.5rem 0rem -0.7rem 0rem;
  }

  .SharePageListComponentbutton{
    margin: 0rem 0rem 0rem 0rem !important;
    height: 1.5rem !important;
    width: 3rem !important;
    border-radius: 4rem !important;
    /* border: none; */
    background-color: var(--secondary-color) !important;
    font-size: 0.7rem !important;
  }

  .SharePageListComponentimagefile{
    height: 2rem;
    width: 2rem;
    border-radius: 5rem;
  }
  .SharePageListComponentimage{
    margin: 0rem 0rem 0rem -0.2rem !important;
  }
  .famoussocialmediaicon{
    border-radius: 10rem;
    height: 2.2rem;
    width: 2.2rem;
    
  }
#searchField{
    width: 8rem;
  }
  /* buy me cake animation */

.box {
  position: relative;
  display: block;
  height: 2.2rem;
  width: 2.2rem;
  background: linear-gradient(0deg, rgb(255, 255, 255), #fdfdfd);
}

.glowing::before{
  border-radius: 5rem;
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, #e8f74d, #ff6600d9, #00ff66, #13ff13, #ad27ad, #bd2681, #6512b9, #ff3300de, #5aabde);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  
  50% {
    background-position: 400% 0;
  }
  
  100% {
    background-position: 0 0;
  }
}


}

/* Styles for screens 900px wide or wider */
@media (min-width: 600px) {
  .SharePageListComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 0rem 0rem 0.6rem 0rem;
    background-color: var(--third-color);
    border-radius: 3rem;
    padding: 0.2rem 3rem 0.2rem 3rem;
    /*  */
  }
  .SharePageListComponentinfo-h{
    font-size: 1rem;
    margin: 0.5rem 0rem -0.7rem 0rem;
  }

  .SharePageListComponentimagefile{
    height: 3rem;
    width: 3rem;
    border-radius: 5rem;
  }

  .SharePageListComponentbutton{
    margin: 0rem 0rem 0rem 0rem !important;
    height: 1.9rem;
    width:  4rem;
    border-radius: 4rem !important;
    /* border: none; */
    background-color: var(--secondary-color) !important;
    font-size: 0.7rem !important;
  }
  .SharePageListComponentimage{
    margin: 0rem 0rem 0rem 0rem !important;
  }
  .famoussocialmediaicon{
    border-radius: 10rem;
    height: 2.5rem;
    width: 2.5rem;
    
  }

  /* buy me cake animation */

.box {
  position: relative;
  display: block;
  background: linear-gradient(0deg, rgb(255, 255, 255), #fdfdfd);
}

.glowing::before{
  border-radius: 5rem;
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, #e8f74d, #ff6600d9, #00ff66, #13ff13, #ad27ad, #bd2681, #6512b9, #ff3300de, #5aabde);
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  
  50% {
    background-position: 400% 0;
  }
  
  100% {
    background-position: 0 0;
  }
}

}











.SharePageListComponentinfo-p{
  font-size: 0.85rem;
  color: var(--grey)
}


.SharePageListComponentfirstdiv{
  /* border: 1px solid #000; */

}
.SharePageListComponentseconddiv{
  /* border: 1px solid #000; */
  width: 20.1rem;
  overflow: hidden;
  white-space: nowrap;
}
.SharePageListComponentthirddiv{
  /* border: 1px solid #000; */
  width: 4rem;
  display: flex;
  flex-direction: center !important;
}
.SharePageListComponentbuttondiv{
  /* border: 1px solid #000; */
  width: 4rem;
}
.sharepage-contentlist-inner{
  margin: 0rem 0rem 0rem 0rem;
}

.sharepage-top-inner{
  display:  flex;
  justify-content: center;
  gap: 5rem;
}


/* 6. Skeleton classes */
.skeletonlink{
  border-radius: 1.2rem;
}
